import Vue from 'vue';
import { Component } from 'vue-property-decorator';

interface PublicIndexConfig {
  config: {
    baseurl: string
    companyLogoUrl: string
    companyEnLogoUrl: string
  }
}


@Component({
    components: {
    },
})
export default class SignPreview extends Vue {
    item: gnb.UserBusinessCard = Object.create(null);
    lang: String = '';
 /*                       <span style="display: inline-block; vertical-align: middle; width: 2px; height: 2px; margin: 0 4px; background-color: #000;" > </span>*/

    getPreviewHtml() {
        let template = `
        <div class="EumBusinessCard ${this.lang}" style="width: 350px; display: inline-block; border: 1px solid #dedede; padding: 10px 20px; box-sizing: border-box;">
        <input type="hidden" value="${Number(new Date())}" />`;
        switch (this.lang) {
            case 'Korean':
                template += `                
                    <h1 style="margin: 0 0 10px;">
                    </h1>
                    <div style="display: inline-block; padding-right: 10px; vertical-align: top;">
                        <h2 style="font-family: dotum; font-size: 18px; margin: 0 0 4px;">${this.item.displayName || ''}</h2>
                        <span style="display: inline-block; vertical-align: middle; font-family: dotum; font-size: 14px; color: #666;">${this.item.deptName || ''}</span>
                        <br/>
                        <span style="display: inline-block; vertical-align: middle; font-family: dotum; font-size: 14px; color: #666;">${this.item.titleName || ''}</span>
                    </div>
                    <div style="display: inline-block; vertical-align: top; padding-left: 10px; border-left: 2px solid #eee; margin-bottom: 6px;">
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666; display: inline-block; margin-right: 6px;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">T</span> ${this.item.officeTel || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">F</span> ${this.item.officeFax || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">M</span> ${this.item.mobileTel || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">E</span> ${this.item.email || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;">${this.item.homePage || ''}</p>
                    </div>
                    <div style="font-family: dotum; font-size: 12px; color: #333;">
                        <p style="margin: 10px 0 0">${this.item.homeAddress || ''}</p>
                    </div>`;
                break;
            case 'English':
                template += `                
                    <h1 style="margin: 0 0 10px;">
                    </h1>
                    <div style="display: inline-block; padding-right: 10px; vertical-align: top;">
                        <h2 style="font-family: dotum; font-size: 18px; margin: 0 0 4px;">${this.item.displayNameEng || ''}</h2>
                        <span style="display: inline-block; vertical-align: middle; font-family: dotum; font-size: 14px; color: #666;">${this.item.deptNameEng || ''}</span>
                        <br/>
                        <span style="display: inline-block; vertical-align: middle; font-family: dotum; font-size: 14px; color: #666;">${this.item.titleNameEng || ''}</span>
                    </div>
                    <div style="display: inline-block; vertical-align: top; padding-left: 10px; border-left: 2px solid #eee; margin-bottom: 6px;">
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666; display: inline-block; margin-right: 6px;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">T</span> ${this.item.officeTel || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">F</span> ${this.item.officeFax || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">M</span> ${this.item.mobileTel || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;"><span style="display: inline-block; width: 16px; text-align: center; margin-right: 2px; font-weight: 600; color: #000;">E</span> ${this.item.email || ''}</p>
                        <p style="margin: 0 0 2px; font-family: dotum; font-size: 13px; color: #666;">${this.item.homePage || ''}</p>
                    </div>
                    <div style="font-family: dotum; font-size: 12px; color: #333;">
                        <p style="margin: 10px 0 0">${this.item.homeAddressEng || ''}</p>
                    </div>`;
                break;
            case 'Free':
                template += `${this.item.freeContent}`;
                break;
        }
        template += '</div>';
        return template;
    }

    mounted() {
            var html = this.getPreviewHtml();
            const element = document.getElementById('prvArea')
            if(element) element.innerHTML  = html
    }

    created() {
            if (this.$route.params) {

                // const signInfo = JSON.parse(this.$route.params.item);
                let obj = (window as Window).opener?.signInfo;

                const signInfo = JSON.parse(decodeURIComponent(obj));
                const language = this.$route.params.lang;

                this.item = signInfo;
                this.lang = language;
            }
    }
}
